import React from 'react'
import './App.css';
import logo from './assets/logo.svg'
import linkedinRosa from './assets/linkedinRosa.svg'
import linkedinAzul from './assets/linkedinAzul.svg'
import logoPequenaCinza from './assets/logoPequenaCinza.svg'
import logoPequenoBranco from './assets/logoPequenoBranco.svg'


function App() { 

    function changeClassMobile(clickedId) {
        
      const irmao = document.getElementById(clickedId.target.id);

      const sobrePt = document.getElementById("sobre-pt-mb");
      const sobreEn = document.getElementById("sobre-en-mb");

      if(clickedId.target.className === "lg_mb lg_mb_desact") {
        clickedId.target.className = "lg_mb lg_mb_act"
        if (clickedId.target.id === "id_en_mb") {
          const pt = irmao.previousSibling
          pt.className = "lg_mb lg_mb_desact"
          sobrePt.style.display = "none";
          sobreEn.style.display = "block";
        } else {
          const en = irmao.nextSibling
          en.className = "lg_mb lg_mb_desact"
          sobrePt.style.display = "block";
          sobreEn.style.display = "none";
        }
      }       
    }
  
    function changeClass(clickedId) {
      
      const irmao = document.getElementById(clickedId.target.id);

      const sobrePt = document.getElementById("sobre-pt");
      const sobreEn = document.getElementById("sobre-en");

      if(clickedId.target.className === "lg lg_desact") {
        clickedId.target.className = "lg lg_act"
        if (clickedId.target.id === "id_en") {
          const pt = irmao.previousSibling
          pt.className = "lg lg_desact"
          sobrePt.style.display = "none";
          sobreEn.style.display = "block";
        } else {
          const en = irmao.nextSibling
          en.className = "lg lg_desact"
          sobrePt.style.display = "block";
          sobreEn.style.display = "none";
        }
      }       
    }


    return (
      <>
        <div id="masterMobile">
          <div id="topoRosaMobile"></div>

          <div className="sobreRihzMobile" id="sobre-pt-mb">
            <img src={logo} alt="Rihz Logo" id="logoSobreMobile"/>  
            <div id="paragrafo1Mobile">
            <span><font color="#1e84ea"><b>Em breve nosso site estará no ar!</b></font></span>
            <span><font color="#ff337a"><b> Mas…</b></font></span>
            </div>
            <div id="paragrafo2Mobile">
            <span><font color="#ff337a"><b>… Já que estamos aqui,</b></font></span>
            <span> nós somos a Rihz, especializados em programação de <b>Visual Aids</b> para agências de publicidade <b>HealthCare</b>, com mais de <b>10 anos experiência</b> no mercado farmacêutico. Temos soluções que agilizam a sua entrega com real qualidade e performance para o seu <b>VisualAid.</b></span>
            </div>          
            <p><b>Com as nossas agências parceiras</b>, conseguimos reduzir o tempo de entrega em torno de <b>60%</b> e melhorar a performance de carregamento e animações em mais de <b>65%</b> de todos os VisualAids que trabalhamos.</p>
            <h3><b>Conheça a Rihz: contato@rihz.com.br</b></h3>
            <div id="linkedinMobile">
              <a href="https://www.linkedin.com/in/rafaelnct/"><img src={linkedinRosa} alt="Linkedin Rafael"/></a>
              <a href="https://www.linkedin.com/in/iliampav/"><img src={linkedinAzul} alt="Linkedin Iliam"/></a>
            </div>
          </div>
                    
          <div className="sobreRihzMobile" id="sobre-en-mb">
            <img src={logo} alt="Rihz Logo" id="logoSobreMobile"/>  
            <div id="paragrafo1Mobile">
            <span><font color="#1e84ea"><b>Soon our website will be live!</b></font></span>
                <span><font color="#ff337a"><b> But…</b></font></span>
              </div>
              <div id="paragrafo2">
                <span><font color="#ff337a"><b>… Since we are here,</b></font></span>
                <span> we are Rihz, specialized in <b>Visual Aids</b> programming for <b>HealthCare</b> advertising agencies, with over <b>10 years experience</b> in the pharmaceutical market. We have solutions that reduce your deadline with  quality and performance for your <b>VisualAid</b>.</span>
              </div>          
              <p><b>With our partner agencies</b>, we were able to reduce delivery time by <b>60%</b> and improve loading and animation performance in more than <b>65%</b> of all VisualAids we’d worked with.</p>
              <h3><b>Meet Rihz: contato@rihz.com.br</b></h3>
            <div id="linkedinMobile">
              <a href="https://www.linkedin.com/in/rafaelnct/"><img src={linkedinRosa} alt="Linkedin Rafael"/></a>
              <a href="https://www.linkedin.com/in/iliampav/"><img src={linkedinAzul} alt="Linkedin Iliam"/></a>
            </div>
          </div>
          
          <div id="baixoAzulMobile">
            <div id="lgs_mb">
                <p id="id_pt_mb" className="lg_mb lg_mb_act" onClick={changeClassMobile}>PT</p>
                <p id="id_en_mb" className="lg_mb lg_mb_desact" onClick={changeClassMobile}>EN</p>
            </div>
            <img src={logoPequenoBranco} alt="Logo Rihz Pequena"/>
          </div>
        </div>
      
        <div id="masterWeb">
          <div id="container-esquerdo">
            <img src={logo} alt="Rihz Logo" id="logoSobre"/>
            <div className="sobreRihz" id="sobre-pt">
              <div id="paragrafo1">
                <span><font color="#1e84ea"><b>Em breve nosso site estará no ar!</b></font></span>
                <span><font color="#ff337a"><b> Mas…</b></font></span>
              </div>
              <div id="paragrafo2">
                <span><font color="#ff337a"><b>… Já que estamos aqui,</b></font></span>
                <span> nós somos a Rihz, especializados em programação de <b>Visual Aids</b> para agências de publicidade <b>HealthCare</b>, com mais de <b>10 anos experiência</b> no mercado farmacêutico. Temos soluções que agilizam a sua entrega com real qualidade e performance para o seu <b>VisualAid.</b></span>
              </div>          
              <p><b>Com as nossas agências parceiras</b>, conseguimos reduzir o tempo de entrega em torno de <b>60%</b> e melhorar a performance de carregamento e animações em mais de <b>65%</b> de todos os VisualAids que trabalhamos.</p>
              <h3><b>Conheça a Rihz: contato@rihz.com.br</b></h3>
            </div>
            <div className="sobreRihz" id="sobre-en">
              <div id="paragrafo1">
                <span><font color="#1e84ea"><b>Soon our website will be live!</b></font></span>
                <span><font color="#ff337a"><b> But…</b></font></span>
              </div>
              <div id="paragrafo2">
                <span><font color="#ff337a"><b>… Since we are here,</b></font></span>
                <span> we are Rihz, specialized in <b>Visual Aids</b> programming for <b>HealthCare</b> advertising agencies, with over <b>10 years experience</b> in the pharmaceutical market. We have solutions that reduce your deadline with  quality and performance for your <b>VisualAid</b>.</span>
              </div>          
              <p><b>With our partner agencies</b>, we were able to reduce delivery time by <b>60%</b> and improve loading and animation performance in more than <b>65%</b> of all VisualAids we’d worked with.</p>
              <h3><b>Meet Rihz: contato@rihz.com.br</b></h3>
            </div>
            <div id="linkedin">
              <a href="https://www.linkedin.com/in/rafaelnct/"><img src={linkedinRosa} alt="Linkedin Rafael"/></a>
              <a href="https://www.linkedin.com/in/iliampav/"><img src={linkedinAzul} alt="Linkedin Iliam"/></a>
            </div>
          </div>
          <div id="container-direito">
            <div id="logoGrande"></div>
            <div id="LogoPequenaCinza">
              <div id="lgs">
                <p id="id_pt" className="lg lg_act" onClick={changeClass}>PT</p>
                <p id="id_en" className="lg lg_desact" onClick={changeClass}>EN</p>
              </div>
              <img src={logoPequenaCinza} alt="Logo Rihz Pequena"/>
            </div>        
          </div>
        </div>
      </>
    );

  }

export default App;
